<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue'
import type { InteractiveSegmenter, MPMask } from '@mediapipe/tasks-vision'
import XButton from '../../../components/XButton.vue'
import IndeterminateQuestionBox from '../../../components/IndeterminateQuestionBox.vue'

const props = defineProps<{
  image: string
  lastThumbnail: string
  snapshotLoading: boolean
  segmenter: InteractiveSegmenter
  show: boolean
}>()
const cameraoverlay = ref<InstanceType<typeof HTMLCanvasElement>>()

watch(() => props.image, async () => {
  const imgAsBlob: Blob = await $fetch(props.image)
  const img: HTMLCanvasElement = await blobToCanvas(imgAsBlob)
  if (!img || !img.height || !img.width)
    return
  props.segmenter.segment(
    img,
    {
      keypoint: {
        x: 0.5,
        y: 0.5,
      },
    },
    (result) => {
      if (result.categoryMask == null)
        return
      drawSegmentation(result.categoryMask)
    },
  )
})

function drawSegmentation(mask: MPMask) {
  const width = mask.width
  const height = mask.height
  const maskData = mask.getAsFloat32Array()
  const canvas = document.getElementById(
    'cameraoverlay',
  ) as HTMLCanvasElement

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  if (ctx == null)
    return
  ctx.fillStyle = '#00000000'
  ctx.fillRect(0, 0, width, height)
  ctx.fillStyle = 'rgba(255, 203, 61, 0.7)'

  maskData.forEach((category, index) => {
    if (Math.round(category * 255.0) === 0) {
      const x = (index + 1) % width
      const y = (index + 1 - x) / width
      ctx.fillRect(x, y, 1, 1)
    }
  })
}

function blobToCanvas(blob: Blob): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    img.addEventListener('load', () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx!.drawImage(img, 0, 0)
      resolve(canvas)
    })

    img.addEventListener('error', () => {
      reject(new Error('Failed to load the image.'))
    })

    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string')
        img.src = reader.result
      else
        reject(new Error('Invalid result type.'))
    }
    reader.onerror = () => {
      reject(new Error('Failed to read the Blob.'))
    }
    reader.readAsDataURL(blob)
  })
}

const instance = getCurrentInstance()

function hideImageModal() {
  instance!.emit('hideImageModal')
}

function confirmImageSaveModal() {
  instance!.emit('confirmImageSaveModal')
}

function findGallery() {
  window.location.href = '/gallery'
}
</script>

<template>
  <div v-if="show" class="modal">
    <div class="modal-background" />
    <div id="content" class="modal-content">
      <canvas id="cameraoverlay" ref="cameraoverlay" />
      <img :src="props.image">
    </div>
    <IndeterminateQuestionBox />
    <button
      class="circle-buttom"
      :loading="snapshotLoading" style="display: flex; justify-content: center;"
      @click="confirmImageSaveModal"
    >
      <v-icon icon="mdi-check-circle" style="align-self: center;" />
    </button>
    <button class="circle-photo" :loading="snapshotLoading" style="z-index: 104;" @click="findGallery">
      <img v-if="lastThumbnail" :src="lastThumbnail">
    </button>
    <XButton :loading="snapshotLoading" style="z-index: 104;" @click="hideImageModal" />
  </div>
</template>

<style scoped>
#cameraoverlay {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* Check https://cs.github.com/SamChristy/sudoku-solver/blob/88dcbffea26d448f24f1d848af213cafe5161789/src/hooks/useScanner.ts?q=intrinsic-scale */
  object-fit: cover;
}

#cameraoverlay {
  z-index: 100;
  padding: 8px;
  /* Needed to fit the 8px padding on the image */
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.modal-content {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 102;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.modal-content img {
  width: 100%;
  height: 100vh;
  display: block;
  border-radius: 16px;
  padding: 8px;
}

.circle-photo img {
  padding: 0px !important;
}

.circle-buttom {
  color: #7EF8D5;
  font-size: 42px;
  z-index: 104;
}

.circle-buttom:active {
  color: #006B56BF
}

.footer__nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 800px;
  flex-wrap: nowrap;
  background-color: #fff;
  border-top: 1px solid #C0C0C0;
}
</style>
